.maindiv {
  width: 100%;
  padding: 8px;
  /* background-color: brown; */
}
.heading {
  font-weight: bold;
  padding: 8px 0 8px 4px;
  background-color: cornsilk;
}
.input_div {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 8px;
}
.bank_input {
  display: flex;
  flex: 9;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.7);
  height: 32px;
  padding: 4px;
}
.input_div > button {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  font-weight: 300;
}
.bank_list {
  list-style: none;
  margin: 0 !important;
  padding: 4px;
  background-color: white;
  height: 300px;
  overflow-y: auto;
}
.bank_list > li {
  height: 24px;
  color: black;
  margin-bottom: 8px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3) !important;
}

.bank_list > li:last-of-type {
  border-bottom: none !important;
}
