.maindiv {
  /* background-color: aquamarine; */
  padding: 8px;
}
.heading {
  font-weight: bold;
  padding: 8px 0 8px 4px;
  background-color: cornsilk;
  margin-bottom: 8px;
}

.name_div {
  padding: 8px 0;
}
.number_div {
  padding: 8px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.cvv_div {
  display: flex;
  justify-content: space-between;
  padding: 8px 0;
}
input {
  height: 36px;
  padding: 4px;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.7);
}
.card_name {
  width: 100%;
}
.card_num_input {
  width: 90%;
}
.card_icon {
  height: 32px;
  width: 32px;
  /* background-color: whitesmoke; */
}
.cvv_input {
  width: 30%;
}
.expiry_date {
  width: 30%;
}
.add_card_btn {
  width: 100%;
  background-color: tomato;
  height: 40px;
  color: white;
  margin-top: 12px;
  border-radius: 8px;
  font-size: 16px;
}
.error_msg {
  font-size: 12px;
  color: tomato;
}
