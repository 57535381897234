.AuthScreenDiv {
  position: fixed;
  bottom: 60px;
  left: 0;
  /* height: 220px; */
  box-sizing: border-box;
  width: calc(100vw);
  margin: 0 4px;
  z-index: 9;
  box-shadow: -1px -5px 5px 0px rgba(0, 0, 0, 0.79);
  background: white;
  box-shadow: 0 2px 40px 0 rgba(0, 0, 0, 0.3);
}
.AuthHeadingDiv {
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 16px;
  padding: 8px;
}

.AuthHeadingDiv > .countryCodePicker {
  border-bottom: 1px solid #ffffff;
  background-color: transparent;
}

.countryCodePicker {
  border-bottom: 1px solid #ffffff;
  background-color: transparent !important;
  color: black !important;
}

.countryCodePicker p {
  color: black;
}

.AuthWelcomeText {
  font-size: 32px;
  line-height: 32px;
  color: #ff5800;
  letter-spacing: 2px;
  display: flex;
  margin: 0;
  box-sizing: border-box;
}

.AuthPhoneText {
  display: flex;
  margin: 8px 0 16px;
  color: slategrey;
  font-size: 16px;
  box-sizing: border-box;
  transition: 0.5s;
}

.AuthNextButton {
  margin: 24px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row-reverse;
  transition: 0.5s;
}

.AuthNextButton > button {
  width: 100%;
}

.AuthOtpInput {
  margin: auto;
  box-sizing: border-box;
  height: 40px;
  width: 200px;
  text-align: center;
  font-size: 24px;
  letter-spacing: 3px;
  padding: 0 12px;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #ffffff;
  color: #000000;
  vertical-align: middle;
  caret-color: #f55000;
}

input::-webkit-input-placeholder {
  font-size: 12px;
  line-height: 3;
}

.AuthOtpInput:focus {
  outline-width: 0;
  outline: none;
}

.AuthScreenLogoImage {
  height: 100px;
  width: 100px;
  object-fit: contain;
}
.headerDiv {
  position: relative;
  height: 40px;
  margin-bottom: 4px;
  /* border-bottom: 1px silver solid; */
  border-top: 1px;
  text-align: center;
  padding: auto;
  margin-top: 10%;
}

.headerDiv h3 {
  font-weight: normal;
  color: #000;
  margin: 10px;
}
.close {
  position: absolute;
  right: 8px;
  top: 8px;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 30px;
}

.auth_button {
  height: 40px;
  margin-left: 16px;
  margin-top: 8px;
  color: rgb(255, 255, 255);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 4px !important;
  font-size: 14px;
  background: #ff5800;
  border-radius: 8px;
  font-weight: bold;
}

.ResendButton {
  height: 24px;
  /* margin-left: 16px;
  margin-top: 8px; */
  background: transparent;
  color: #ff5800;
  /* border: #ff5800 1px solid; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 4px !important;
  width: 120px;
  font-size: 12px;
  margin: auto;
}

@media screen and (max-width: 380px) {
  .AuthWelcomeText {
    font-size: 24px;
    letter-spacing: 2px;
  }
}

.customization_modal {
  position: fixed;
  bottom: 132px;
  top: 55px;
  left: 0;
  right: 0;
  background: rgba(254, 254, 254, 1);
  overflow-y: auto;
  z-index: 99;
  border-radius: 8px;
  opacity: 1;
}

.overlay_div {
  position: fixed;
  top: 0 !important;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 120;
  background-color: rgba(0, 0, 0, 0.6);
  filter: blur(2px);
}

.form_style {
  padding: 24px;
  padding-bottom: 50px;
}

.option_div {
  color: red;
}
.sku_div {
  margin-bottom: 16px;
}

.sku_text {
  font-size: 14px;
  font-weight: 700;
  color: #f55000;
  text-transform: capitalize;
}

.main_option_div {
  margin-bottom: 16px;
}

.cust_cat_name {
  font-size: 14px;
  columns: #f55000;
  font-weight: 700;
  text-transform: capitalize;
}
.cust_cat_text {
  font-size: 10px;
  color: grey;
}

.list_item {
  margin-bottom: 4px;
  font-size: 12px;
  display: flex;
  justify-content: space-between;
}
.list_item:last-of-type {
  margin-bottom: 8px;
}

.list_item:first-of-type {
  margin-top: 8px;
}

.list_item svg {
  fill: #f55000;
}

.radio_cust:first-of-type {
}
.check_cust {
}
.check_cust :first-of-type {
}
.button_div {
  width: calc(100vw - 16px);
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  position: fixed;
  bottom: 120px;
  left: 8px;
  right: 8px;
  height: 50px;
  background-color: rgba(230, 229, 229, 1);
  margin-bottom: 10px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.cust_add_button {
  width: 40%;
  background: #00873d;
  color: rgb(0, 0, 0);
  height: 40px;
  border-radius: 12px;
  font-size: 14px;
  text-align: center;
}

.cust_cancel_button {
  width: 40%;
  background: #ff4040;
  color: rgb(0, 0, 0);
  height: 40px;
  border-radius: 12px;
  font-size: 14px;
  text-align: center;
}

.productInfo {
  height: 100px;
  width: 100%;
  display: flex;
  padding: 10px;
  margin-left: 5%;
}

.productImage {
  height: 80px;
  width: 80px;
  border-radius: 100%;
}

.productImage img {
  border-radius: inherit;
  object-fit: cover;
}

.productDescription {
  width: 100%;
  padding-top: 20px;
}

.productDescription p {
  color: 100%;
  margin-left: 20px;
  color: #f55000;
}

.headerDiv {
  height: 150px;
  /* background-color:grey; */
}

.btnGroup {
  display: block;
  padding: 30px;
  position: absolute;
  bottom: 0;
  width: 100%;
}

.btnGroup input {
  border: 1px solid #bb0000;
  width: 100%;
  margin-bottom: 10px;
  height: 60px;
  color: #bb0000;
  padding: 10px;
  background: transparent;
  border-left: none;
  border-right: none;
  border-top: none;
  font-size: 30px;
}

.btnGroup button {
  background-color: #bb0000;
  width: 100%;
  height: 40px;
  color: rgb(0, 0, 0);
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
  border-radius: 5px;
  margin-bottom: 10px;
}
.infoText {
  padding: 20px;
  width: 80%;
  text-align: center;
  margin: auto;
  /* color: white; */
  border-radius: 3px;
  top: 5px;
  position: relative;
  color: black;
  font-size: 16px;
}

.seatNo {
  display: block;
  text-align: center;
  padding: auto;
  font-size: 48px;
  color: #bb0000;
  font-weight: bold;
  margin-top: 40px;
}
.swal_close_button {
  background-color: #f55000 !important;
}

.overlaydiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 120px);
  width: 100%;
  border-radius: 15px;
  color: black;
  box-sizing: border-box;
  margin: 0 4px;
  z-index: 999;
}

.overlaydiv > p {
  margin-top: 16px;
  font-size: 16px;
}

.swal_close_button {
  background-color: #f55000 !important;
}
