.main_div {
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
}
.loader {
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid #f55000;
  width: 50px;
  height: 50px;
  -webkit-animation: spin 1s linear infinite; /* Safari */
  animation: spin 1s linear infinite;
}
.text_div {
  margin-top: 16px;
  font-size: 14px;
  display: flex;
  align-items: center;
}

.loading_text {
  color: #ffffff;
}

.first_dot {
  animation: blinks 1s infinite;
  font-size: 18px;
  font-weight: bold;
}
.second_dot {
  font-size: 18px;
  animation: blinks 1s infinite;
  animation-delay: 250ms;
  font-weight: bold;
}

.third_dot {
  font-size: 18px;
  animation: blinks 1s infinite;
  animation-delay: 500ms;
  font-weight: bold;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes blinks {
  50% {
    color: transparent;
  }
  100% {
    color: #ffffff;
  }
}
