.maindiv {
  padding: 16px;
  background: #0f1f39;
  height: 100%;
  overflow-y: auto;
}
.phonediv {
  display: block;
  color: white;
  border-bottom: 1px solid silver;
}

.maindiv label {
  display: block;
  font-size: 12px;
  color: #f55000;
  padding: 8px;
  margin-top: 10px;
}

.maindiv input {
  padding: 8px;
  width: 100%;
  border-bottom: 0.7px solid gray;
}

.phoneinput {
  color: white;
}

.inputdiv {
  border-bottom: 0.8px solid silver;
  color: white;
}
.labeldiv > label {
  margin-left: 0;
}
.inputfield {
  padding: 0;
  border: 1px transparent;
}

input:focus {
  outline: none !important;
}

.savebutton {
  width: 140px;
  height: 40px;
  border: 1px solid #ffffff;
  background-color: #0f1f39;
  border-radius: 0;
  color: white;
  font-size: 18px;
}

.buttondiv {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 18px;
}

.options_div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: #ffffff;
  height: 40px;
  border-bottom: 0.2px solid rgba(255, 255, 255, 0.7);
}
.options_div:last-of-type {
  border-bottom: none;
}

.icon_div {
  margin-right: 12px;
}

.options_text {
  width: 100%;
  line-height: 20px;
  letter-spacing: 0.6px;
  font-size: 20px;
  vertical-align: middle;
}

.layoverloading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 120px);
  width: 100%;
  border-radius: 15px;
  color: white;
}
.layoverloading > p {
  margin-top: 16px;
  font-size: 16px;
}

.overlaydiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 120px);
  width: 100%;
  border-radius: 15px;
  color: white;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
}

.overlaydiv > p {
  margin-top: 16px;
  font-size: 16px;
}

.swal_close_button {
  background-color: #f55000 !important;
}
