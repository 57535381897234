.maindiv {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
}

.heading_div {
  text-align: left;
  width: 100%;
  color: white;
}

.text_area {
  color: black;
  min-height: 180px;
  border: 0.6px solid rgba(255, 255, 255, 0.6);
  width: 100%;
}

.sbumit_button {
  height: 40px;
  width: 100%;
  margin-top: 16px;
  color: white;
  text-transform: uppercase;
  border: 0.6px solid rgba(255, 255, 255, 0.6);
  text-align: center;
  background: transparent;
}

.swal_close_button {
  background-color: #f55000 !important;
}

.overlaydiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 120px);
  width: 100%;
  border-radius: 15px;
  color: white;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
}

.overlaydiv > p {
  margin-top: 16px;
  font-size: 16px;
}
