.wrap {
  max-width: 640px;
  margin: 0 auto;
}
.button {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  background-color: #f55000 !important;
  height: 50px;
  width: 90% !important;
  border: 0.5px rgba(229, 62, 36, 0.7) solid;
  border-radius: 4px;
  padding: 8px;
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.54);
  margin-bottom: 8px;
}
.swal_close_button {
  background-color: rgba(229, 62, 36, 1) !important;
}
.button > p {
  line-height: 35px;
  color: white !important;
  line-height: 30px;
  vertical-align: middle;
  font-size: 14px;
  font-family: Arial, Helvetica, sans-serif;
  letter-spacing: 3px;
  text-align: center;
  margin-left: 8px;
  width: 100%;
}
.main {
  right: 0;
  left: 0;
  position: fixed;
  bottom: 0;
  margin-bottom: 0 !important;
  padding: 0;
  background-color: rgba(0, 0, 0, 0.54);
  z-index: 8;
  height: 100%;
}
.overlay {
  position: fixed;
  bottom: 0;
  height: 300px;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  padding: 8px 24px 16px;
  box-sizing: border-box;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.22),
    0px 14px 56px rgba(0, 0, 0, 0.25);
  animation: fadeIn 0.1s;
  -webkit-animation: fadeIn 0.1s;
}

@keyframes fadeIn {
  0% {
    height: 10px;
  }
  100% {
    height: 300px;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    height: 10px;
  }
  50% {
    height: 100px;
  }
  100% {
    height: 300px;
  }
}

.overlay .btnClose {
  color: #666;
  -webkit-transform: scale(0, 0);
  transform: scale(0, 0);
  -webkit-transition: all 450ms ease-in-out 0;
  transition: all 450ms ease-in-out 0;
}

@media screen and (min-width: 640px) {
  .overlay {
    padding: 0 19%;
  }
  .overlay .social {
    max-width: 62%;
    margin: 0 auto;
  }
}
@media screen and (min-width: 960px) {
  .overlay {
    padding: 0 23.5%;
  }
  .overlay .social {
    min-width: 384px;
    max-width: 50%;
  }
}
@media screen and (min-width: 1280px) {
  .overlay {
    padding: 0 26%;
  }
  .overlay .social {
    min-width: 512px;
    max-width: 48%;
  }
}
@media screen and (min-width: 1440px) {
  .overlay {
    padding: 0 28%;
  }
  .overlay .social {
    min-width: 576px;
    max-width: 44%;
  }
}

.srOnly {
  /* a generic way to visually hide content while remaining accessible to screen readers (h5bp.com) */
  clip: rect(0 0 0 0);
  overflow: hidden;
  position: absolute;
  height: 1px;
  width: 1px;
}
/* input {
    
  } */

.header {
  text-align: center;
  height: 80px;
}
.stats_div {
  text-align: center;
  font-size: 24px;
}

.changeButton {
  color: #ff5500 !important;
}

.input {
  height: 40px !important;
  width: 100% !important;
  border-radius: 4px;
  background-color: rgb(245, 80, 0, 0.2);
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: hidden;
  caret-color: #f55000;
  padding-left: 15px;
  font-weight: bold;
  color: #f55000;
  animation: fadeIn 0.2s;
  -webkit-animation: fadeIn 0.2s;
  /* color: black; */
  /* border: ; */
}

input:focus {
}

.confirmBtn {
  width: 100%;
  height: 40px;
  font-weight: 500;
  font-size: 14px;
  background-color: #f55000;
  color: white;
  font-family: "open sans";
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  margin-top: 24px;
}

.table {
  color: #f55000;
}

.loading_div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.changeBtn {
  background-color: #f55000;
  border-radius: 5px;
  color: white;
  vertical-align: middle;
  margin: 16px;
  font-size: 10px;
  float: right;
}

.loader {
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid #f55000;
  width: 50px;
  height: 50px;
  -webkit-animation: spin 1s linear infinite; /* Safari */
  animation: spin 1s linear infinite;
}

.stats_div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.change_btn {
  margin: 16px;
  color: #ff5500;
}

@keyframes fadeIn {
  0% {
    transform: scale(0.2);
    -webkit-transform: scale(0.2);
  }
  100% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}
@-webkit-keyframes fadeIn {
  0% {
    transform: scale(0.2);
    -webkit-transform: scale(0.2);
  }
  100% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
