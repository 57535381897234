.checkout_div {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  background: #425f8e;
  margin-top: 10px;
  color: white;
  padding: 15px;
}
.bill_table {
  width: 100%;
  display: flex;
}

.accordion_label {
  font-size: 10px;
  color: white;
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 3px;
}
.bDrWKZ {
  border: none !important;
}
.table_row {
  font-size: 10px;
  display: flex;
  flex-direction: row;
  padding: 4px;
}
.store_div {
  margin: 8px 0;
}
.store_detail_div {
  display: flex;
  justify-content: space-between;
}
.store_text {
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
  text-transform: capitalize;
  margin-left: 5px;
}
.status_text {
  color: #ffffff;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
}
.total_row {
  font-size: 10px;
  display: flex;
  flex-direction: row;
  padding: 4px;
}
.total_row .product_div {
  font-weight: bold;
  font-size: 12px;
}
.product_div {
  flex: 3;
}
.qty_div {
  flex: 1;
  text-align: center !important;
}

.price_div {
  flex: 1;
  text-align: right !important;
}
.indicator_div {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 0;
}

.regiontext {
  font-size: 10px;
  margin-left: 6px;
  text-align: center;
}

.region {
  display: flex;
  margin: auto;
}

.timerText {
  font-size: 8px;
  text-align: center;
}

.updateSeatBtn {
  color: #f55000;
  font-size: 10px;
  letter-spacing: 3px;
  text-transform: uppercase;
  height: 40px;
  border-radius: 5px;
  background-color: transparent;
  border: 1px solid white;
  margin: 5px;
  -webkit-animation: glowing 1500ms infinite;
  -moz-animation: glowing 1500ms infinite;
  -o-animation: glowing 1500ms infinite;
  animation: glowing 1500ms infinite;
  font-weight: bold;
}

.invoice_button {
  background: transparent;
  color: #f55000;
  font-size: 14px;
  width: 100%;
}

@-webkit-keyframes glowing {
  0% {
    background-color: rgb(255, 255, 255);
    -webkit-box-shadow: 0 0 2px rgb(255, 255, 255, 0.5);
  }
  50% {
    background-color: rgb(255, 255, 255);
    -webkit-box-shadow: 0 0 10px rgb(255, 255, 255, 0.5);
  }
  100% {
    background-color: rgb(255, 255, 255);
    -webkit-box-shadow: 0 0 2px rgb(255, 255, 255, 0.5);
  }
}

@-moz-keyframes glowing {
  0% {
    background-color: rgb(255, 255, 255);
    -moz-box-shadow: 0 0 2px rgb(255, 255, 255, 0.5);
  }
  50% {
    background-color: rgb(255, 255, 255);
    -moz-box-shadow: 0 0 10px rgb(255, 255, 255, 0.5);
  }
  100% {
    background-color: rgb(255, 255, 255);
    -moz-box-shadow: 0 0 3px rgba(255, 255, 255, 0.5);
  }
}

@-o-keyframes glowing {
  0% {
    background-color: rgb(255, 255, 255);
    box-shadow: 0 0 2px rgb(255, 255, 255, 0.5);
  }
  50% {
    background-color: rgb(255, 247, 247);
    box-shadow: 0 0 10px rgb(255, 255, 255, 0.5);
  }
  100% {
    background-color: rgb(255, 255, 255);
    box-shadow: 0 0 2px rgb(255, 255, 255, 0.5);
  }
}

@keyframes glowing {
  0% {
    background-color: rgb(255, 255, 255);
    box-shadow: 0 0 3px rgb(255, 255, 255, 0.5);
  }
  50% {
    background-color: rgb(255, 255, 255);
    box-shadow: 0 0 10px rgb(255, 255, 255, 0.5);
  }
  100% {
    background-color: rgb(255, 255, 255);
    box-shadow: 0 0 3px rgb(255, 255, 255, 0.5);
  }
}

.seatInfo {
  display: flex;
  justify-content: space-between;
  margin: auto;
  align-items: baseline;
  width: 100%;
  margin-top: 2%;
  margin-bottom: 2%;
}

.seatInfo .seatLabel {
  float: left;
  margin-left: 5px;
  font-size: 14px;
}

.seatInfo .seatNo {
  float: right;
  font-weight: bold;
  color: #f55000;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.6);
}

.seatNo svg {
  margin-left: 3px;
}

.timediv {
  font-size: 12px;
  text-align: right;
  font-weight: bold;
}
