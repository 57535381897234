.maindiv {
  color: white;
  height: calc(100vh - 120px);
  width: 100%;
  top: 54px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.content_div {
  margin-top: 16px;
}
.markdown_div {
  width: 100%;
}
.loadingdiv {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 180px);
  width: 100%;
  justify-content: center;
  align-items: center;
  color: white;
}
.loadingdiv > p {
  margin-top: 20px;
}
