.wrapper {
  height: calc(100% - 56px);
  margin: auto;
  border: 1px solid #333;
  background-color: #0f1f39;
}
header {
  text-transform: uppercase;
  font-size: 30px;
  background-color: #f55000;
  color: #f5f5f5;
  padding: 40px 20px;
  text-align: center;
}
.block {
  background-color: #f5f5f5;
  color: #f55000;
  height: 100px;
  text-align: center;
  padding: 20px;
}
.horizontalScroll {
  background-color: #0f1f39;
  overflow: hidden;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  padding: 12px 0;
}
.item {
  border: 1px solid #333;
  display: inline-block;
  width: 150px;
  background-color: #f5f5f5;
  text-align: center;
  /* padding: 50px 30px; */
  font-size: 20px;
  margin: 0 10px;
  height: 50px;
  line-height: 50px;
  border: 1px solid #f55000;
  border-radius: 5px;
  vertical-align: middle;
  font-family: "open sans";
  font-size: 16;
}
footer {
  color: #f5f5f5;
  text-align: center;
  background-color: #f55000;
  padding: 20px;
}

.active_cat_div {
  background-color: #f55000;
  color: white;
}

.helpText {
  font-size: 4px;
}
.grid {
  display: grid;
  grid-template-columns: auto auto;
  padding: 30px;
  height: 450px;
  overflow: scroll;
}
.grid_item {
  border: 1px solid #333;
  display: inline-block;
  width: 150px;
  background-color: #f5f5f5;
  text-align: center;
  margin-bottom: 20px;
  font-size: 20px;
  /* margin: 0 10px; */
  height: 50px;
  line-height: 50px;
  border-radius: 5px;
  vertical-align: middle;
  font-family: "open sans";
  font-size: 16;
  margin-right: 20px;
}
