.maindiv {
  position: relative;
  background-color: #0f1f39;
  /* background-color: rebeccapurple; */
}
.search_input_div {
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-bottom: 1px solid grey;
}

.content_div {
  height: calc(100% - 160px);
  display: flex;
  flex: auto;
  align-items: center;
  overflow-y: hidden;
  overflow-x: auto;
  box-sizing: border-box;
}

.containerColumn {
  margin-left: 8px;
  display: flex;
  flex-direction: column;
  padding: 8px 0;
  height: 100%;
}
.containerColumn :first-child {
  margin-bottom: 12px;
}
.containerColumn:first-of-type {
  margin-left: 32px;
}
.containerColumn :last-of-type {
  margin-right: 8px;
}

.search_input {
  height: 90%;
  background-color: bisque;
  border-radius: 4px;
  width: 80%;
  padding: 0 8px;
  font-size: 14px;
  color: white;
}

.noresult {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 180px);
  width: 100%;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;
}

.loadingdiv {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 180px);
  width: 100%;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;
}
.loadingdiv > p {
  margin-top: 20px;
}
.loadingdiv > h6 {
  padding: 20px;
}
.search_button {
  color: white;
  font-size: 12px;
  border: 1px solid white;
  border-radius: 8px;
}

.storepicker {
  height: 80px;
  background-color: #0b182e;
  width: 100vw;
  position: fixed;
  bottom: 50px;
  left: 0;
  right: 0;
  padding: 0 8px;
  color: white;
  display: flex;
  overflow-x: auto;
}
.storepicker::-webkit-scrollbar {
  width: 0;
  display: none;
}

.searchButton {
  /* width: auto; */
  font-family: "open sans";
  font-size: 12px;
  color: white;
  border: 1px solid #f55000;
  background-color: transparent;
  height: 35px;
  /* padding: 10px; */
  border-radius: 20px;
  text-align: center;
  justify-content: middle;
  margin: 20px 10px 0 0;
  min-width: 100px;
}

.cat_title {
  font-weight: 600;
  color: white;
  height: 20px;
  top: 0;
  margin-bottom: 6px;
  margin-top: 6px;
  margin-left: 32px;
}
.cardcontainer {
  display: flex;
  flex: auto;
  height: 100%;
  align-items: center;
  overflow-y: hidden;
  overflow-x: auto;
  box-sizing: border-box;
}

.noresultmessage {
  font-weight: bold;
  color: #f55000;
}
.noresultmessage1 {
  font-size: 12px;
  color: white;
}
.noresultmessage2 {
  color: white;
}
.btngroup {
  display: flex;
  justify-content: space-evenly;
  margin-top: 10px;
  height: 70px;
}
.btngroup button {
  width: 40%;
  text-align: center;
  background-color: #f55000;
  color: white;
  height: 40px;
  border-radius: 5px;
  font-family: "open sans";
  font-weight: bold;
  letter-spacing: 1px;
  font-size: 12px;
}
