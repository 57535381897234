.maindiv {
  height: 50px;
  width: 100vw;
  background: #0f1f39;
  display: flex;
  align-items: center;
  padding: 0 8px;
}
.cart_button {
  position: relative;
  background: rgba(255, 255, 255, 0.3);
  padding: 6px;
  border-radius: 20px;
}

.cart_button :active {
  outline: none;
}

.cart_button :focus {
  outline: none;
}

.firstdiv {
  flex: 1;
  align-items: center;
  text-align: center;
  margin: auto;
  padding-top: 8px;
}

.seconddiv {
  flex: 6;
  align-items: center;
  color: white;
  font-size: 12px;
  font-weight: bold;
}
.thirddiv {
  flex: 1;
  align-items: center;
}

.item_indicator {
  position: absolute;
  top: -1px;
  right: -2px;
  height: 18px;
  width: 18px;
  border-radius: 18px;
  font-size: 10px;
  background-color: #f55000;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.search_input {
  height: 38px;
  background-color: transparent;
  width: 38px;
  /* border: 1px solid grey; */
  color: grey;
  padding: 8px;
  letter-spacing: 1px;
  border-radius: 38px;
  margin-right: 10px;
  font-family: "open sans";
  font-weight: 600;
}
