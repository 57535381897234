.checkout_modal_open {
  background-color: rgba(254, 254, 254, 1);
  display: flex;
  color: #000000;
  flex-direction: column;
  padding: 16px;
  height: 100%;
  width: 100vw;
}
.checkout_div {
}
.error_animation_div {
  height: 300px;
  width: 300px;
  color: red;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.checkout_top_div {
  width: 100%;
  display: flex;
  /* justify-content: center; */
  flex-direction: column;
  border-bottom: 1px gray solid;
  min-height: 100px;
  padding-bottom: 12px;
}
/* 
.checkout_top_div > div {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
} */
.logo_div {
  display: grid !important;
  grid-template-columns: repeat(2, 1fr);
  justify-items: center !important;
  /* justify-content: center !important; */
  margin-bottom: 16px;
}

.brand_logo {
  height: 60px;
  object-fit: contain;
}
.cart_header {
  margin-bottom: 14px;
  color: #f55000;
}
/* 
.delivery_location_div {
} */
.token_number_div {
  text-align: right;
}
.top_div_title {
  color: #000000;
  font-size: 14px;
  margin-bottom: 2px;
  text-transform: capitalize;
}
.top_div_value {
  color: #f55000;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 16px;
}

.background_cover_checkout {
  position: fixed;
  top: 55px;
  left: 8px;
  right: 8px;
  bottom: 55px;
  background-color: rgba(0, 0, 0, 0.3);
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

.checkout_detail_div {
  display: flex;
  flex-direction: column;
  overflow-y: scroll !important;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  margin-top: 12px;
  position: relative;
  padding-bottom: 120px;
}
.checkout_detail_div::-webkit-scrollbar {
  width: 0;
}
.order_detail_div {
  display: flex;
  flex-direction: column;
  text-transform: capitalize;
  margin-bottom: 12px;
  min-height: max-content;
  /* min-height: 100%; */
}
.order_detail_div > p {
  margin: 0 0 4px 0;
  color: #5b5b5b;
}
.order_content_main {
  font-size: 14px;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.order_item_extra_content {
  display: flex;
  font-size: 10px;
  margin-bottom: 8px;
  flex-direction: column;
}
.order_item_div {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}
.order_item_name {
  display: flex;
  flex: 3;
  width: 50%;
  font-size: 12px;
  text-overflow: ellipsis;
  overflow: hidden;
  color: #000000;
  font-weight: 400;
  line-height: 14px;
}
.order_qty_management {
  display: flex;
  flex: 2;
  width: 20%;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.order_qty {
  margin: 0 4px;
  width: 20px;
}
.order_qty_button {
  width: 24px;
  height: 24px;
  border-radius: 24px;
  background-color: #000000;
  color: #ffffff;
  font-size: 16px;
  line-height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.order_item_price {
  display: flex;
  flex: 2;
  color: #5b5b5b;
  justify-content: flex-end;
  text-align: right;
}
.order_item_price > span {
  margin-left: 4px;
}
.extra_content_div {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  min-height: max-content;
}
.coupon_code_div {
  display: flex;
  flex-direction: column;
  margin: 4px 0;
}
.coupon_code_div > div {
  display: flex;
}
.coupon_code_input {
  height: 30px;
}
.coupon_code_button {
  background-color: green;
  color: #ffffff;
}
.coupon_code_text {
  font-size: 10px;
  margin-top: 4px;
}
.cart_total_div {
  display: flex;
  flex-direction: column;
}
.cart_total_item {
  margin: 4px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 14px;
}
.cart_total_item_title {
  flex: 4;
}
.cart_total_item_value {
  flex: 1;
  text-align: right;
}

.pay_button_div {
  height: 60px;
  width: 100%;
  position: sticky;
  bottom: 60px;
  align-items: center;
  display: flex;
  justify-content: center;
  background-color: rgba(254, 254, 254, 1);
}

.pay_button {
  width: 80%;
  height: 40px;
  box-shadow: 0 0 2px rgba(255, 255, 255, 0.5);
  border-radius: 10px;
  background-color: #bb0000;
  position: relative;
  /* display: flex;
  flex-direction: row-reverse;
  padding-right: 20vw; */
  font-size: 16px;
  color: white;
}
.pay_button_amount {
  position: absolute;
  left: 0;
  top: 0;
  min-width: 60px;
  padding: 0 8px;
  display: flex;
  align-items: center;
  font-size: 14px;
  letter-spacing: 1.5px;
  border-radius: 10px;
  height: 40px;
  background-color: rgba(255, 255, 255, 0.2);
}

.layoverloading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.5);
  height: 100%;
  width: 100%;
  box-shadow: 0 2px 4px rgba(255, 255, 255, 0.5),
    inset 0 1px 3px rgba(255, 255, 255, 0.5);
  border-radius: 15px;
}

.loadingDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  border-radius: 15px;
  color: #000000;
  text-align: center;
  padding: 0 32px;
}

.loadingDiv > button {
  margin-top: 16px;
  background-color: #f55000;
  color: white;
  height: 40px;
  width: 80%;
  font-size: 14px;
}

.modalClose {
  display: none;
}

.paymodel_header_div {
  position: relative;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.payment_close_button {
  position: absolute;
  right: 0;
  top: 0;
  color: white;
  font-size: 16px;
  height: 30px;
  width: 30px;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 40px;
  margin: 8px;
}

.headerText {
  font-size: 16px;
  text-align: center;
  vertical-align: center;
  font-style: normal;
  color: white;
  font-weight: 100;
  margin-top: 20px;
}

.buttonGroup {
  text-align: center;
  margin-top: 40px;
}

.buttonGroup button {
  background-color: #0f1f39;
  border: 0.5px solid #1f951c;
  width: 80%;
  height: 40px;
  border-radius: 3px;
  margin-bottom: 20px;
}
.buttonGroup span {
  color: white;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 2px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); /*dim the background*/
  z-index: 998;
}

/* .instructionBox{

} */
.instruction_div {
  margin: 4px 0 8px;
}

.instructionTextarea {
  width: 100%;
  height: 50px;
  border-radius: 5px;
  border: 0.8px solid rgba(218, 218, 218, 0.8);
  transform: width 4s;
}

.instructionBtnGrp {
  display: flex;
  justify-content: space-evenly;
}

.instructionBtnGrp .submit {
  background-color: #f55000;
  height: 32px;
  width: 35%;
  border: 1px solid #f55000;
  text-transform: uppercase;
  letter-spacing: 3px;
  color: white;
  border-radius: 5px;
}

.instructionBtnGrp .cancel {
  background-color: transparent;
  height: 32px;
  width: 35%;
  border: 1px solid #f55000;
  color: #f55000;
  border-radius: 5px;
  letter-spacing: 3px;
  text-transform: uppercase;
}

.instructionBtn {
  background-color: transparent;
  width: 100%;
  border: none;
  padding: 0;
  font-size: 10px;
}

.instructionBtn span {
  color: #f55000;
  float: left;
  font-style: bold;
}

input:focus {
  outline: 0;
}
textarea:focus {
  outline: 0;
}

.store_name {
  font-size: 14px;
}

.customization_toggle {
  color: #f55000;
  font-style: bold;
  margin: 0;
}
.customization_text_div {
  transition: all 200ms;
}
.sku_text {
  font-size: 10px;
  line-height: 11px;
  text-transform: lowercase;
}

.fassaidiv {
  display: flex;
  text-align: center;
  margin: auto;
  padding: 20px;
  font-size: 12px;
}

.fassaidiv p {
  vertical-align: middle;
  margin-left: 5px;
  margin-top: 5px;
  font-weight: bold;
  color: #3f3f3f;
}

.store_name_div {
  display: flex;
  justify-content: space-between;
}

.sla_div {
  display: flex !important;
  margin: 0;
  padding: 0;
  height: 12px !important;
}

.sla_div > p {
  font-size: 10px;
  line-height: 10px;
  margin-left: 4px;
}

.clock_icon {
  height: 10px !important;
  width: 10px !important;
}

.recom_list {
  display: flex;
  flex-direction: row;
  /* width: 92vw;
  margin: 0 4vw;
  overflow-x: auto; */
}
.card_div {
  display: flex;
  flex: 1;
  margin-left: 8px;
}
