.block {
  /* padding: 20px; */
}

.countdownBlock {
  display: flex;
  flex-direction: unset;
  justify-content: space-evenly;
  padding: 10px;
  padding-top: 0px;
}
.countdownElement {
  text-align: center;
}

.countdownDigit {
  font-size: 48px;
  color: white;
  font-weight: 600;
  padding: 10px;
}

.countdownDigitLabel {
  font-size: 12px;
  color: white;
  font-weight: 600;
  padding: 10px;
}
