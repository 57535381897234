.customization_modal {
  position: fixed;
  bottom: 112px;
  top: 60px;
  left: 8px;
  right: 8px;
  bottom: 112px;
  background: rgba(254, 254, 254, 1);
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 999;
  border-radius: 8px;
}

.overlay_div {
  position: fixed;
  top: 0 !important;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.6);
  filter: blur(2px);
}

.form_style {
  padding: 24px;
  padding-bottom: 50px;
}

.option_div {
  color: red;
}
.sku_div {
  margin-bottom: 16px;
}

.sku_text {
  font-size: 14px;
  font-weight: 700;
  color: #f55000;
  text-transform: capitalize;
}

.main_option_div {
  margin-bottom: 16px;
}

.cust_cat_name {
  font-size: 14px;
  columns: #f55000;
  font-weight: 700;
  text-transform: capitalize;
}
.cust_cat_text {
  font-size: 10px;
  color: grey;
}

.list_item {
  margin-bottom: 4px;
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  text-transform: capitalize;
}
.list_item:last-of-type {
  margin-bottom: 8px;
}

.list_item:first-of-type {
  margin-top: 8px;
}

.list_item svg {
  fill: #f55000;
}

.radio_cust:first-of-type {
}
.check_cust {
}
.check_cust :first-of-type {
}
.button_div {
  width: calc(100vw - 16px);
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  position: fixed;
  bottom: 112px;
  left: 8px;
  right: 8px;
  height: 60px;
  background: rgba(254, 254, 254, 1);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.cust_add_button {
  width: 40%;
  background: #00873d;
  color: white;
  height: 40px;
  border-radius: 12px;
  font-size: 14px;
  text-align: center;
}

.cust_cancel_button {
  width: 40%;
  background: #ff4040;
  color: white;
  height: 40px;
  border-radius: 12px;
  font-size: 14px;
  text-align: center;
}

.productInfo {
  min-height: 100px;
  width: 100%;
  display: flex;
  padding: 10px;
  align-items: center;
}

.productImage {
  height: 80px;
  width: 80px;
  border-radius: 100%;
}

.productImage img {
  border-radius: inherit;
  object-fit: cover;
}

.productDescription {
  width: 100%;
  padding-top: 20px;
}

.product_name {
  color: 100%;
  margin-left: 20px;
  margin-right: 8px;
  color: #f55000;
}

.product_desc {
  font-size: 10px;
  margin-right: 8px;
  margin-left: 20px;
  color: black;
  line-height: normal;
}

.description_text {
  font-size: 10px !important;
  margin-bottom: 4px;
}
