.maindiv {
  overflow: auto;
  padding-bottom: 12px;
  -webkit-overflow-scrolling: touch;
}

.layoverloading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  border-radius: 15px;
  color: white;
  text-align: center;
}
.layoverloading > p {
  margin-top: 16px;
  font-size: 16px;
}
