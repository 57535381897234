.maindiv {
  /* height: calc(100vh - 112px); */
  overflow-y: auto;
  max-width: 800px;
  margin: auto;
}
.imagediv {
  width: 100%;
  object-fit: contain;
}

.image_carousel {
  width: 100%;
  padding: 2%;
}

.last_orders_div {
  width: 100%;
  padding: 8px;
}

.headerText {
  color: white;
  font-size: 14px;
  font-size: bold;
}

.token {
  height: 40px;
  width: 100%;
  background-color: #425f8e;
  border-radius: 5px;
  display: flex;
}
.tokenNumber {
  width: 100%;
  float: right;
  font-size: 14px;
  text-align: right;
  padding: 8px 20px 0 0;
  color: white;
  font-weight: bold;
  letter-spacing: 2px;
}
.tokenText {
  width: 100%;
  padding: 8px 0 0 20px;
  font-size: 14px;
  color: white;
  font-weight: bold;
  letter-spacing: 2px;
}

.setting_card {
  background-color: #688ac1;
  margin: 8px;
  padding: 12px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
}

.edit_mode_div > button {
  background: transparent;
  border-radius: 100%;
  color: #f55000;
  margin-left: 5px;
  display: flex;
}

.edit_mode_div > p {
  font-weight: bold;
}

.edit_mode_div {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 12px;
  color: white;
  font-weight: 500;
  margin-top: 12px;
}

.icon_style {
  height: 28px;
  width: 28px;
  background-color: rgba(254, 254, 254, 0.6);
  border-radius: 24px;
  font-weight: 400;
}

@media only screen and (min-device-width: 375px) and (max-device-width: 667px) {
  .image_carousel {
    width: fit-content;
    margin-top: 0;
  }
}

@-webkit-keyframes glowing {
  0% {
    background-color: rgb(255, 255, 255);
    -webkit-box-shadow: 0 0 2px rgb(255, 255, 255, 0.5);
  }
  50% {
    background-color: rgb(255, 255, 255);
    -webkit-box-shadow: 0 0 10px rgb(255, 255, 255, 0.5);
  }
  100% {
    background-color: rgb(255, 255, 255);
    -webkit-box-shadow: 0 0 2px rgb(255, 255, 255, 0.5);
  }
}

@-moz-keyframes glowing {
  0% {
    background-color: rgb(255, 255, 255);
    -moz-box-shadow: 0 0 2px rgb(255, 255, 255, 0.5);
  }
  50% {
    background-color: rgb(255, 255, 255);
    -moz-box-shadow: 0 0 10px rgb(255, 255, 255, 0.5);
  }
  100% {
    background-color: rgb(255, 255, 255);
    -moz-box-shadow: 0 0 3px rgba(255, 255, 255, 0.5);
  }
}

@-o-keyframes glowing {
  0% {
    background-color: rgb(255, 255, 255);
    box-shadow: 0 0 2px rgb(255, 255, 255, 0.5);
  }
  50% {
    background-color: rgb(255, 247, 247);
    box-shadow: 0 0 10px rgb(255, 255, 255, 0.5);
  }
  100% {
    background-color: rgb(255, 255, 255);
    box-shadow: 0 0 2px rgb(255, 255, 255, 0.5);
  }
}

@keyframes glowing {
  0% {
    background-color: rgb(255, 255, 255);
    box-shadow: 0 0 3px rgb(255, 255, 255, 0.5);
  }
  50% {
    background-color: rgb(255, 255, 255);
    box-shadow: 0 0 10px rgb(255, 255, 255, 0.5);
  }
  100% {
    background-color: rgb(245, 255, 255);
    box-shadow: 0 0 3px rgb(255, 255, 255, 0.5);
  }
}

.orderBtn {
  width: 100%;
  color: white;
  background-color: #f55000;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-size: 16px;
  letter-spacing: 3px;
  height: 40px;
  border-radius: 5px;
  /* margin-right: 5px;
  margin-left: 5px; */
  text-align: center;
  vertical-align: middle;
  padding: auto;
  padding-top: 8px !important;
  font-weight: bold;
}

.orderBtnDiv {
  padding: 8px;
}
