.maindiv {
  /* height: 100vh; */
  /* background-color: whitesmoke; */
  /* width: 100vw; */
  overflow: hidden;
}

.content_div {
  /* height: calc(100vh - 106px); */
}
