html {
  height: 100%;
  width: 100%;
}
body {
  margin: 0;
  font-family: "Open Sans", sans-serif, -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #0f1f39 !important;
  background-image: url("./assets/background.svg");
  background-position: center;
  background-size: cover;
  overflow: hidden;
  height: 100%;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
* {
  box-sizing: border-box !important;
  letter-spacing: 0.6px;
  user-select: none;
  scroll-behavior: smooth;
}

p {
  margin: 0;
  padding: 0;
}

button {
  border: none;
  background: transparent;
}
input,
input:before,
input:after {
  -webkit-user-select: initial;
  -khtml-user-select: initial;
  -moz-user-select: initial;
  -ms-user-select: initial;
  user-select: initial;
}

textarea,
textarea:before,
textarea:after {
  -webkit-user-select: initial;
  -khtml-user-select: initial;
  -moz-user-select: initial;
  -ms-user-select: initial;
  user-select: initial;
}

input:focus {
  outline: 0;
}

.textI {
  color: black;
}

.swal2-styled .swal2-confirm {
  background-color: green !important;
}
