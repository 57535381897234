.overlay {
  background-color: rgba(0, 0, 0, 0.8);
  height: 100vh;
  width: 100vw;
  z-index: 1001;
  position: fixed;
  text-align: center;
  padding: 20px;
  animation: test 0.3s;
  -webkit-animation: test 0.3s;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.modal {
  /* position: fixed;
    bottom: 112px;
    top: 60px;
    left: 8px;
    right: 8px;
    bottom: 112px;
    padding: 20px; */
  /* background: rgba(254, 254, 254, 1); */
  /* overflow-y: auto;
    overflow-x: hidden;
    z-index: 13;
    border-radius: 8px;
    animation: modal .3s;
    -webkit-animation: modal .3s; */
  position: fixed;
  top: 60px;
  left: 8px;
  right: 8px;
  bottom: 112px;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 999;
  border-radius: 8px;
  padding: 20px;
  text-align: left;
  color: white;
  font-family: "open sans";
  outline: 0;
  max-width: 400px;
  max-height: 650px;
  margin: 0 auto;
  animation: modal 0.3s;
  -webkit-animation: modal 0.3s;
  transition: opacity 0.15s linear;
}

.modalDialog {
  position: relative;
}

@keyframes test {
  from {
    background-color: rgba(0, 0, 0, 0.8);
  }
  to {
    background-color: rgba(0, 0, 0, 0.7);
  }
}

@-webkit-keyframes test {
  from {
    background-color: rgba(0, 0, 0, 0.7);
  }
  to {
    background-color: rgba(0, 0, 0, 0.7);
  }
}

@keyframes modal {
  0% {
    transform: scale(0.2);
    -webkit-transform: scale(0.2);
  }
  100% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}
@-webkit-keyframes modal {
  0% {
    transform: scale(0.2);
    -webkit-transform: scale(0.2);
  }
  100% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}

.closebtn {
  position: absolute;
  bottom: 10%;
  left: 0;
  right: 0;
  margin: auto;
  animation: modal 0.3s;
}
.closebtn button {
  color: #f55000;
  font-weight: bold;
  font-size: 20px;
  /* text-align: center; */
}

.modalHeader {
  animation: modal 0.3s;
  -webkit-animation: modal 0.3s;
}

.modalImage {
  left: 0;
}

.modalImage img {
  width: 30vh;
  height: 30vh;
  left: 0;
}

.imageCard {
  /* position: absolute; */
  /* left: -10px;
    align-self: center;
    width: 140px;
    z-index: 100; */
}

.description {
  font-size: 12px;
}

.cartBtn button {
  background-color: rgba(255, 255, 255, 0.1);
  color: black;
  height: 50px;
  width: 90%;
  border-radius: 5px;
  text-align: center;
  vertical-align: middle;
  margin-top: 30px;
  position: absolute;
  bottom: 10px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.sla_div {
  display: flex !important;
  margin: 0;
  padding: 0;
  height: 12px !important;
}

.sla_div > p {
  font-size: 10px;
  line-height: 10px;
}

.clock_icon {
  height: 10px !important;
  width: 10px !important;
}

h4 {
  margin-block-end: 0.5em;
}
