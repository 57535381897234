:root {
  --main-bg-color: rgb(219, 112, 147);
  --store-card-size: 100px;
}
.maindiv {
  position: relative;
}
.layoutdiv {
  /* height: calc(100% - 80px); */
}
.storepicker {
  height: 80px;
  background-color: #0b182e;
  width: 100vw;
  padding: 0 8px;
  color: white;
  display: flex;
  overflow-x: auto;
}
.storepicker::-webkit-scrollbar {
  width: 0;
  display: none;
}
.storeItem {
  height: 80px;
  width: var(--store-card-size);
  display: flex;
  flex: 0 0 var(--store-card-size);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-right: 6px;
  padding: 4px 0;
}
.active_storeItem {
  height: 80px;
  width: var(--store-card-size);
  display: flex;
  flex: 0 0 var(--store-card-size);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #f55000;
  margin-right: 6px;
  padding: 4px 0;
}

.active_storeItem > p {
  font-size: 10px;
  word-wrap: break-word;
  line-height: initial;
}
.storeItem > p {
  font-size: 10px;
  word-wrap: break-word;
  line-height: initial;
}
.storeimage {
  height: 40px;
  width: 40px;
  border-radius: 40px;
  background: gray;
  object-fit: contain;
}

.cardcontainer {
  display: flex;
  flex: auto;
  height: 100%;
  align-items: center;
  overflow-y: hidden;
  overflow-x: auto;
  box-sizing: border-box;
}

.containerColumn {
  margin-left: 8px;
  display: flex;
  flex-direction: column;
}
.containerColumn :first-child {
  margin-bottom: 12px;
}
.containerColumn :last-of-type {
  margin-right: 8px;
}

.loadingdiv {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 180px);
  width: 100%;
  justify-content: center;
  align-items: center;
  color: white;
}
.loadingdiv > p {
  margin-top: 20px;
}

.buzzerBtn {
  background-color: rgba(245, 80, 0, 70);
  border-radius: 100%;
  width: 60px;
  height: 60px;
  position: fixed;
  z-index: 999;
  bottom: 80px;
  right: 0;
  text-align: center;
  box-shadow: 2px 2px 3px rgb(70, 61, 61);
  color: #ffffff;
}
