.main_div {
  width: 100%;
  margin-bottom: 16px;
  background-color: #2c3e50;
  padding: 20px;
  -webkit-box-shadow: 2px 2px 2px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 2px 2px 2px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 2px 2px 2px 0px rgba(0, 0, 0, 0.1);
}
.category_title {
  color: #e63e25;
  background-color: #2c3e50;
  padding: 12px;
  display: flex;
  border-radius: 4px;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
}
.category_name {
}
.item_div {
  transition: all 0.2s;
  display: block;
}
.closed_item_div {
  height: 0;
  display: none;
}
.region_name {
  line-height: 25px;
  padding: 8px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
.region_name:last-of-type {
  border-bottom: none;
}
