.parent {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  height: 100vh;
  width: 100vw;
  justify-items: center;
}

.image_div {
  grid-area: 1 / 1 / 2 / 2;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.region_image {
  height: 100vh;
  width: 100vw;
  max-width: 600px;
  background-position: center;
}
.content_div {
  grid-area: 1 / 1 / 2 / 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.swal_close_button {
  background-color: rgba(229, 62, 36, 1) !important;
}

@media only screen and (min-width: 600px) {
  .welcome_text_div {
    font-weight: bold;
    letter-spacing: 3px;
    margin-top: 200px;
    padding: 8px 16px;
    color: white;
    max-width: 800px;
  }
  .region_image {
    width: 600px;
  }
}

@media only screen and (max-width: 600px) {
  .welcome_text_div {
    font-weight: bold;
    letter-spacing: 3px;
    margin-top: 100px;
    padding: 8px 16px;
    color: white;
    max-width: 800px;
  }
}

.welocme_text {
  font-size: 24px;
  animation: show 1s;
  max-width: 400px;
}

.highlight_text {
  font-size: 300%;
  line-height: 40px;
  animation: show 1s;
}

@keyframes show {
  0% {
    margin-left: -500px;
  }
  100% {
    margin-left: 0px;
  }
}

.order_mode_div {
  margin-bottom: 20px;
  padding: 16px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  max-width: 600px;
}

.ordermodepanel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f55000;
  height: 50px;
  width: 90%;
  border: 0.5px rgba(229, 62, 36, 0.7) solid;
  border-radius: 4px;
  padding: 8px;
  color: white;
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.54);
  margin: 8px 0px;
}
.ordermodepanel:nth-last-child(2) {
  margin-bottom: 16px;
}
.ordermodepanel > p {
  line-height: 35px;
  width: 100%;
  vertical-align: middle;
  font-size: 14px;
  font-family: Arial, Helvetica, sans-serif;
  letter-spacing: 3px;
  text-align: center;
}
