.addressContainer {
  color: black;
  background: white;
  padding: 20px;
  margin-top: 10px;
  border-radius: 5px;
  box-shadow: 4px 7px rgba(255, 255, 255, 0.1);
  margin: 10px;
}

.addressContainer h3 {
  color: #f55000;
}

.inputField {
  width: calc(100%);
  padding: 10px;
  border: none;
  margin-bottom: 10px;
  box-shadow: 0 1px 16px 0 rgba(0, 0, 0, 0.09);
  height: 50px;
  border-radius: 5px;
  padding-left: 20px;
}

.addButton {
  margin-top: 15px;
}
