.maindiv {
  height: 100vh;
  width: 100vw;
  object-fit: contain;
  background-position: center;
  background-size: contain;
}
.redirectImage {
  height: 100vh;
  width: 100vw;
  object-fit: fill;
}
.imagelayover {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  padding: 16px 0;
}
.welcometext {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 28px;
}
.locationLogo {
  margin-top: 5vh;
}
.logo_welcome {
  width: auto;
  height: 40px;
  background-size: contain;
  margin: 20px;
}
.title_ph {
  color: rgba(255, 255, 255, 1);
  font-size: 22px;
  margin-top: 30px;
  font-weight: 400;
  padding: 20px;
}
.title_ph > h4 {
  margin-bottom: 10px;
  font-weight: normal;
}
.title_ph > h1 {
  font-weight: normal;
  margin-top: 0;
}

.default_btn {
  padding: 8px;
  width: 90%;
  color: #ffffff;
  border: none;
  background-color: black;
}

.continueDiv {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  bottom: 40px;
  width: 100%;
}

.fullscreenPrmissionDiv {
  display: block;
  margin-bottom: 20px;
  position: relative;
  color: white;
  padding-left: 35px;
  font-size: 18px;
}

/* Hide the browser's default checkbox */
.fullscreenPrmissionDiv > input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.fullscreenPrmissionDiv:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.fullscreenPrmissionDiv input:checked ~ .checkmark {
  background-color: #2196f3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.fullscreenPrmissionDiv input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.fullscreenPrmissionDiv .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.layoverloading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  color: white;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
}

.layoverloading > p {
  margin-top: 16px;
  font-size: 16px;
}
