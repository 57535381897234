p {
  margin: 0;
  padding: 0;
}

.card {
  position: relative;
  overflow: hidden;
  border-radius: 16px;
  display: flex;
  flex-direction: column-reverse;
  animation: fadeIn .3s;
  -webkit-animation: fadeIn .3s;
}

.imageCard {
  position: absolute;
  left: -10px;
  align-self: center;
  width: 140px;
  z-index: 100;
}

@keyframes fadeIn{
  0%{
    transform: scale(0.2);
    -webkit-transform: scale(0.2);;
  }
  100%{
    transform: scale(1);
    -webkit-transform: scale(1);;
  }
}
@-webkit-keyframes fadeIn{
  0%{
    transform: scale(0.2);
    -webkit-transform: scale(0.2);;
  }
  100%{
    transform: scale(1);
    -webkit-transform: scale(1);;
  }
}

.svg_mask {
  position: absolute;
  top: -10px;
  left: -110px;
  height: 200px;
  width: 300px;
}
.item_contains_div {
  position: absolute;
  top: 8px;
  left: 8px;
  width: 24px;
  height: 24px;
  border-radius: 2px;
  z-index: 140;
}
.blurdiv {
  z-index: 101;
  position: absolute;
  /* backdrop-filter: blur(3px); */
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
}
.overlay_div {
  padding: 8px;
  padding-right: 18px;
  font-weight: 500;
  height: 50%;
  display: flex;
  z-index: 120;
  flex-direction: column-reverse;
}
.contentDiv {
  margin-right: 40px;
  margin-bottom: 8px;
}
.contentDiv > p {
  font-size: 14px;
  line-height: 16px;
  font-weight: 600;
}
.short_contentDiv {
  margin-right: 40px;
}
.short_contentDiv > p {
  font-size: 14px;
  line-height: 16px;
  font-weight: 600;
}
.cardButton {
  position: absolute;
  bottom: 4px;
  right: 4px;
  height: 30px;
  width: 30px;
  border-radius: 30px;
  background-color: rgba(255, 255, 255, 0.1);
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
}
.item_count_button {
  position: absolute;
  font-size: 12px;
  top: -14px;
  right: -12px;
  height: 24px;
  width: 24px;
  border-radius: 24px;
  background: transparent;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
}
.card_qty_manager {
  position: absolute;
  bottom: 8px;
  right: 8px;
  display: flex;
  align-content: center;
  justify-content: space-between;
  height: 20px;
  width: 60px;
}
.card_qty_manager > button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  border-radius: 20px;
  width: 20px;
}
.card_qty_manager > p {
  font-size: 14px;
  width: 30px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cardButton:active {
  border: none;
  outline: 0;
}
.cardButton:focus {
  outline: 0;
}

.addItem {
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  z-index: 151;
  position: absolute;
  opacity: 0.9;
  font-size: 12px;
  transition: font-size 3s;
  text-align: center;
  vertical-align: middle;
  padding: 20px;
  /* transition: all 0.5s ease; */
  line-height: initial;
  /* transform: scale(1.1); */
  padding-top: 40%;
  font-weight: bold;
  animation: fadeIn .5s;
  -webkit-animation: fadeIn .5s;
}

@keyframes fadeIn{
  from {font-size: 0;}
  to {font-size: 12px;}
}

@-webkit-keyframes fadeIn{
  from {font-size: 0;}
  to {font-size: 12px;}
}


.animatetext {
  transform: height 1s;
}

.description_view {
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  z-index: 150;
  position: absolute;
  opacity: 0.9;
  font-size: 12px;
  transition: font-size 3s;
  text-align: left;
  padding: 20px;
  transition: all 0.5s ease;
  line-height: initial;
}
.description_close {
  bottom: 0;
  position: absolute;
  bottom: 5%;
  left: 45%;
}

.description_text {
  font-size: 10px !important;
  line-height: normal;
  text-overflow: ellipsis;
  line-clamp: 2;
}
.description_div {
  height: 22px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 4px;
}

.description_div p {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.store_image_div {
  height: 35px;
  width: 35px;
  position: absolute;
  top: 8px;
  right: 8px;
  border-radius: 8px;
  border: none;
  z-index: 4;
}

.store_image_div > img {
  height: 100%;
  width: 100%;
  object-fit: contain;
  border-radius: 8px;
}

@media all and (max-width: 400px) {
  .contentDiv > p {
    font-size: 11px;
    margin-bottom: 6px !important;
  }
  .short_contentDiv > p {
    font-size: 11px;
    margin-bottom: 6px !important;
  }
}

@media all and (max-width: 800px) {
  .contentDiv > p {
    margin-bottom: 6px !important;
  }
  .short_contentDiv > p {
    margin-bottom: 6px !important;
  }
}
.sla_div {
  display: flex !important;
  margin: 0;
  padding: 0;
  height: 12px !important;
}

.sla_div > p {
  font-size: 10px;
  line-height: 10px;
}

.clock_icon {
  height: 10px !important;
  width: 10px !important;
}
