.maindiv {
  padding: 16px;
  background: #0f1f39;
  height: 100%;
  overflow-y: auto;
}
.phonediv {
  color: white;
  border-bottom: 1px solid silver;
}
.phoneinput {
  color: white;
}

.inputdiv {
  border-bottom: 0.8px solid silver;
  color: white;
}
.labeldiv > label {
  margin-left: 0;
}
.inputfield {
  padding: 0;
  border: 1px transparent;
}

input:focus {
  outline: none !important;
}

.savebutton {
  width: 140px;
  height: 40px;
  border: 1px solid #ffffff;
  background-color: #0f1f39;
  border-radius: 0;
  color: white;
  font-size: 18px;
}

.buttondiv {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 18px;
}

.options_div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: #ffffff;
  height: 80px;
  border-bottom: 0.2px solid rgba(255, 255, 255, 0.7);
}
.options_div:last-of-type {
  border-bottom: none;
}

.icon_div {
  margin-right: 12px;
}

.options_text {
  width: 100%;
  line-height: 20px;
  letter-spacing: 0.8px;
  font-size: 12px;
  vertical-align: middle;
}

.fAQkVd {
  display: none;
}
.button_div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.logout_button {
  height: 40px;
  width: 60%;
  background: transparent;
  color: white;
  border: rgba(255, 255, 255, 0.7) 1px solid;
  align-self: center;
  margin: 20px 0;
}
