.main_div {
  display: flex;
  height: 100%;
}
.infoDiv {
  display: flex;
  flex-direction: column;
  position: relative;
  overflow-x: auto;
  color: white;
}
.cat_indicator_div {
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #0f1f39;
  width: 24px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px;
}
.store_loading {
  height: 40px;
  width: 40px;
  border-radius: 40px;
  object-fit: contain;
  margin-bottom: 14px;
}

.cat_indicator_div::-webkit-scrollbar {
  display: none;
  width: 0;
}

.active_cat_div {
  color: #f55000;
  font-size: 11px;
  font-weight: 700;
  letter-spacing: 1px;
  margin-bottom: 20px;
  padding: 0 2px;
  overflow-wrap: break-word;
  transition: all 200ms;
  writing-mode: vertical-lr;
  transform: rotate(180deg);
}
.cat_div {
  font-size: 11px;
  transition: all 200ms;
  color: white;
  padding: 0 2px;
  margin-bottom: 20px;
  overflow-wrap: break-word;
  writing-mode: vertical-rl;
  transform: rotate(180deg);
}

.cat_div > p {
  white-space: nowrap;
}

.active_cat_div > p {
  white-space: nowrap;
}

.cat_title {
  font-weight: 600;
  color: white;
  height: 20px;
  top: 0;
  margin-bottom: 6px;
  margin-top: 6px;
  margin-left: 6px;
}

.cardcontainer {
  display: flex;
  flex: auto;
  align-items: center;
  overflow-y: hidden;
  overflow-x: auto;
  box-sizing: border-box;
}

.containerColumn {
  margin-left: 8px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
}
.containerColumn :first-child {
  margin-bottom: 12px;
}
/* .containerColumn:first-of-type {
  margin-left: 32px;
} */
.containerColumn :last-of-type {
  margin-right: 8px;
}

.loadingdiv {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 180px);
  width: 100%;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;
}
.loadingdiv > p {
  margin-top: 20px;
}
.loadingdiv > h6 {
  padding: 20px;
}
