.btn_search > img {
  transition: transform 0.25s;
}
.btn_search:hover > img {
  transform: scale(1.2);
}
.btn_search:focus {
  outline: none;
}

.search {
  font-family: "open sans", sans-serif;
  color: black;
  letter-spacing: 1px;
}

.search::placeholder {
  font-family: "open sans", sans-serif;
}
.search:focus {
  outline: none;
}

.searchbar {
  padding: 10px;
}

.searchbox {
  height: 60px;
  width: 100%;
  max-width: 100%;
  background: white;
  border-radius: 10px;
  position: relative;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 10px;
  display: flex;
}
.searchbox > .btn-menu {
  padding: 16px;
  background: transparent;
  border: none;
  cursor: pointer;
}
.searchbox > .search {
  border: none;
  width: 100%;
}
.searchbox > .btn_search {
  padding: 16px;
  background: transparent;
  border: none;
  cursor: pointer;
}

@keyframes slideInUp {
  from {
    -webkit-transform: translate3d(0, 15%, 0);
    transform: translate3d(0, 15%, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

/* @import url("https://fonts.googleapis.com/css?family=Lexend+Deca|Dosis&display=swap"); */
/* body {
  margin: 0;
  padding: 16px;
  flex: 1;
  background: #2F94D8;
  justify-content: center;
  align-items: center;
  font-family: 'Lexend Deca', sans-serif;
  overflow-Y: none !important;
} */

button img {
    transition: transform .25s;
  }
  button:hover > img {
    transform: scale(1.2);
  }
  button:focus {
    outline: none;
  }
  
  input {
    font-family: 'open sans', sans-serif;
    color: black;
    letter-spacing: 1px;
  }
  
  input::placeholder {
    font-family: 'open sans', sans-serif;
  }
  input:focus {
    outline: none;
  }
  
  .searchbar{
      padding: 10px;
  }
  
  .searchbox {
    height: 60px;
    width: 100%;
    max-width: 100%;
    background: white;
    border-radius: 10px;
    position: relative;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 10px;
    display: flex;
  }
  .searchbox > .btnMenu {
    padding: 16px;
    background: transparent;
    border: none;
    cursor: pointer;
  }
  .searchbox > .search {
    border: none;
    width: 100%;
  }
  .searchbox > .btnSearch {
    padding: 16px;
    background: transparent;
    border: none;
    cursor: pointer;
  }
  
  @keyframes slideInUp {
    from {
      -webkit-transform: translate3d(0, 15%, 0);
      transform: translate3d(0, 15%, 0);
      visibility: visible;
    }
    to {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }
  
