.maindiv {
  padding: 8px;
}

.heading {
  font-weight: bold;
  padding: 8px 0 8px 4px;
  background-color: cornsilk;
}
.wallet_list {
  padding: 4px;
  overflow: auto;
  margin: 0 !important;
}
