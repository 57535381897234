.countryCodePicker {
  display: flex;
  max-width: 500px;
  min-width: 200px;
  width: 100%;
  margin: auto;
  border-bottom: 1px solid #ffffff;
  box-sizing: border-box;
  color: #ffffff;
  padding-bottom: 5px;
}

.styled_select {
  display: flex;
  height: 40px;
  width: 50px;
  margin: 0;
  background-color: transparent;
}
.countryCodePicker input {
  outline-width: 0;
  outline: none;
}

.countryCodePicker > p {
  width: 80px !important;
  font-size: 24px;
  margin: 0px;
  padding: 0px;
  line-height: 40px;
  text-align: center !important;
  color: black;
}

.countryCodePicker input {
  height: 40px;
  width: 100%;
  display: flex;
  border: 0;
  font-size: 24px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  letter-spacing: 2px;
  margin: 0;
  margin-left: 4px;
  padding: 0 12px;
  box-sizing: border-box;
  background: transparent;
  color: #000000;
  border-bottom: 1px solid #f55000;
}

.styled_select select {
  background: transparent;
  border: none;
  font-size: 24px;
  height: 40px;
  width: 50px;
}
