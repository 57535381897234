.btn {
  /* margin: 50px auto;
    border-radius: 25px;
    background-color: #5300e8;
    box-shadow: 0 2px 4px 0 #888888;
    display: inline-block;
    padding: 15px 50px;
    color: #ffffff; */

  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(245, 80, 0, 1);
  height: 50px;
  width: 100%;
  border: 0.5px rgb(245, 80, 0) solid;
  font-size: 12px;
  border-radius: 4px;
  padding: 8px;
  color: white;
  box-shadow: 1px 1px 5px 0px rgba(245, 80, 0, 0.54);
  margin-bottom: 8px;
}

.btn p {
  margin: auto;
  font-weight: bold;
  font-size: 1.2em;
  letter-spacing: 2px;
}

.center {
  text-align: center;
}

.ripple {
  position: relative;
  overflow: hidden;
}
.ripple .rippleContainer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.ripple .rippleContainer span {
  transform: scale(0);
  border-radius: 100%;
  position: absolute;
  opacity: 0.5;
  background-color: #ffffff;
  animation: ripple 1000ms;
}

@keyframes ripple {
  to {
    opacity: 0;
    transform: scale(2);
  }
}
