.main_div {
  /* overflow-y: hidden; */
  transition: all 0.5s;
}
.content_div {
  display: flex;
  flex-direction: row;
  width: 100%;
  overflow-x: auto;
}

.title_div {
  margin-bottom: 8px;
}

.content_div::-webkit-scrollbar {
  width: 0;
}

.content_div > div {
  display: flex;
  flex: 1;
  margin-left: 8px;
}
