.maindiv {
  background: #0f1f39;
  /* background: transparent; */
  height: 56px;
  width: 100vw;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  color: white;
}

.maindiv::-webkit-scrollbar {
  width: 0 !important;
  visibility: hidden !important;
}

.firstdiv {
  align-items: center;
  text-align: center;
  vertical-align: middle;
  display: flex;
  justify-content: center;
}

.second_div {
  align-items: center;
  text-align: center;
  display: flex;
  justify-content: center;
}

.seconddiv {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 2;
}

.thirddiv {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.explore_button {
  height: 30px;
  width: 60px;
  border-radius: 14px;
  background-color: rgba(220, 220, 220, 0.7);
  color: white;
  font-weight: 700;
}

.icon_icon {
  font-weight: 400;
}

button:focus {
  outline: none;
}
button:active {
  outline: none;
}

.btn {
  background-color: rgba(255, 255, 255, 0.7);
  max-width: 100px;
  width: 100px;
  height: 30px;
  border-radius: 40px;
  border: none;
  opacity: 0.4;
  color: black;
  display: flex;
  justify-content: center;
  letter-spacing: 5px;
  font-family: inherit;
  font-size: 10px;
}

.cta {
  width: 30px;
  height: 30px;
  border-radius: 30px;
  border: none;
  color: #fff;
  transition: width 0.7s;
  background: none;
  display: flex;
  font-size: 12px;
  justify-content: space-evenly;
  align-items: center;
}
.explore {
  width: 100px;
  background-color: rgba(255, 255, 255, 0.3);
}
.ctaClick .explore {
  width: 100px;
}
.cta .buttonText {
  opacity: 0;
  /* transition: opacity .5s; */
  position: absolute;
  width: 100%;
  left: 0;
}

.ctaClick .buttonText {
  opacity: 1;
  transition: opacity 3s;
}

.iconSelect {
  margin-bottom: 10px;
  transition: margin-bottom 3s;
}

.ctaClick svg {
  float: left;
  margin-left: 10%;
}

.spantext {
  /* display: none; */
  font-size: 12px;
  left: 5px;
  color: black;
}

.ctaClick span {
  opacity: 1;
  transition: opacity 2s;
  display: flex;
  transition: display 3s;
}
.spantext {
  margin-left: 30px;
}

.cart_button {
  position: relative;
  background: rgba(255, 255, 255, 0.3);
  padding: 6px;
  height: 32px;
  width: 32px;
  border-radius: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pay_bill_button {
  position: relative;
  background-color: rgba(255, 255, 255, 0.3);
  padding: 6px;
  height: 32px;
  border: none;
  color: #fff;
  transition: width 0.7s;
  display: flex;
  font-size: 12px;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 30px;
}

.cart_button :active {
  outline: none;
}

.cart_button :focus {
  outline: none;
}

.item_indicator {
  position: absolute;
  top: -1px;
  right: -2px;
  height: 18px;
  width: 18px;
  border-radius: 18px;
  font-size: 10px;
  background-color: #f55000;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* .textDivExplore{
	margin-left: 30px;
} */

@media only screen and (min-device-width: 375px) and (max-device-width: 667px) {
}
