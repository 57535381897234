:root {
  --bg-color: #0f1f39;
}

.maindiv {
  background: var(--bg-color);
  width: 100%;
  color: white;
  padding: 18px;
  overflow-y: auto;
  max-width: 800px;
  margin: auto;
}
.maindiv::-webkit-scrollbar {
  width: 0;
}
.topdiv {
  display: block;
  padding: 8px 0;
  justify-content: center;
  height: 120px;
  margin-bottom: 20px;
}
.search_div {
  margin: 12px 0;
  color: white;
}
.search_input {
  color: white;
  background-color: rgba(255, 255, 255, 0.3);
}
.search_input::placeholder {
  color: white;
  font-weight: 400;
}
.titleText {
  font-size: 24px;
  margin-top: 14px;
  font-weight: 600;
}

.content_div {
  margin-top: 40px;
}

.region_accordion {
  margin-bottom: 16px;
}
.regionlist {
  display: flex;
  height: calc(100vh - 130px);
  flex-direction: column;
}

.regionlistItem {
  display: flex;
  align-items: center;
  text-transform: capitalize;
  /* border: 1px solid #656565; */
  background: transparent;
  /* border: 0.5px solid #faa47c; */
  border-radius: 8px;
  height: 40px;
  font-size: 16px;
  color: #d2d2d2;
  text-align: left;
  margin-bottom: 8px;
  padding: 0 8px;
}

.regionName {
  font-size: 8px;
}

.locationlogo {
  margin-right: 8px;
}

.regionLandingdiv {
  position: fixed;
  top: 0;
  left: 0;
  padding: 16px;
  height: 100vh;
  width: 100vw;
  background-size: cover;
  background-repeat: no-repeat;
}

.welcomeText {
  color: rgba(255, 255, 255, 1);
  width: 100%;
  font-size: 22px;
  margin-top: 60px;
  font-weight: 400;
  padding: 0 16px;
}
.welcomeText > span {
  font-size: 40px;
  font-weight: 600;
}

.locationLogodiv {
  margin-left: 16px;
  margin-top: 15vh;
}

.regionName {
  font-size: 24px;
}

.buttondiv {
  position: absolute;
  left: 0;
  right: 0;
  padding: 0 16px;
  bottom: 30px;
}
.nextButton {
  width: 100%;
  height: 40px;
  background: #ff5000;
  border: none;
  color: white;
}

@media only screen and (max-width: 400px) {
  .regionlistItem {
    font-size: 12px;
  }
  .titleText {
    font-size: 20px;
  }
  .welcomeText {
    font-size: 16px;
  }
  .welcomeText > span {
    font-size: 24px;
  }
  .regionName {
    font-size: 16px;
  }
}

.logodiv {
  text-align: center;
}

.spandiv {
  margin-top: 16px;
}
