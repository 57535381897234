.maindiv {
  position: fixed;
  top: 0px;
  bottom: 10px;
  left: 0px;
  right: 0px;
  background: whitesmoke;
  z-index: 999;
  border-radius: 8px;
  height: calc(100vh - 20px);
}

.search_header {
  display: flex;
  justify-content: space-between;
  height: 50px;
  align-items: center;
  padding: 8px;
  background: black;
}

.list_div {
  height: calc(100vh - 70px);
  overflow-x: auto;
  padding-bottom: 30px;
}
.icons {
  height: 30px;
  width: 30px;
  color: white;
}
.search_box {
  width: 100%;
  border: 1px solid gray;
  border-radius: 0;
  height: 40px;
  padding: 0 4px;
}

.flightListItem {
  display: inline-flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 4px;
  padding: 8px;
  border-bottom: 0.6px solid rgba(151, 151, 151, 0.6);
  border-right: 0.6px solid rgba(151, 151, 151, 0.6);
  text-align: left;
  color: #f55000;
}
.flghtNameDiv {
  flex: 1.5;
}
.statusDiv {
  flex: 1;
  text-align: left;
}

.flightDatediv {
  flex: 1;
  text-align: right;
}

.statusText {
  color: rgba(229, 62, 36, 1);
  font-size: 12px;
  font-weight: 500;
}

.gatesText {
  font-size: 14px;
}

.destinationText {
  font-size: 14px;
  font-weight: bold;
}

.flightNumber {
  color: rgba(229, 62, 36, 1);
  font-size: 14px;
  font-weight: bold;
}

.departureDate {
  font-size: 12px;
  font-weight: 500;
}

.departureTime {
  font-size: 14px;
  font-weight: 500;
}

@media only screen and (max-width: 340px) {
  .fieldText {
    font-size: 12px;
  }
  .fieldtitle {
    font-size: 12px;
  }
  .boardingpass {
    padding: 16px 8px;
  }
  .datarow {
    margin: 4px 0;
    text-align: left;
  }
}

.headerText {
  font-size: 14px;
  color: white;
}
