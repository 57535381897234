.maindiv {
  padding: 8px;
}
.heading {
  font-weight: bold;
  padding: 8px 0 8px 4px;
  background-color: cornsilk;
  margin-bottom: 16px;
}
.upi_input {
  display: block;
  padding: 4px;
  margin-bottom: 8px;
  width: 100%;
}
.verify_button {
  width: 100%;
  background-color: tomato;
  height: 40px;
  border-radius: 8px;
  font-size: 14px;
  color: white;
  margin-top: 8px;
}

.error_message {
  font-size: 10px;
  color: red;
}
