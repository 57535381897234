.seatmodal_modal {
  position: fixed;
  bottom: 56px;
  top: 56px;
  left: 0;
  right: 0;
  right: 8px;
  background: rgba(254, 254, 254, 0.9);
  /* height: calc(100vh - 110px); */
  height: calc(100vh - 112px);
  overflow-y: auto;
  z-index: 999999;
  border-radius: 8px;
  opacity: 1;
}

.overlay_div {
  position: fixed;
  top: 0 !important;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 120;
  background-color: rgba(0, 0, 0, 0.6);
  filter: blur(2px);
}

.form_style {
  padding: 24px;
  padding-bottom: 50px;
}

.option_div {
  color: red;
}
.sku_div {
  margin-bottom: 16px;
}

.sku_text {
  font-size: 14px;
  font-weight: 700;
  color: #f55000;
  text-transform: capitalize;
}

.main_option_div {
  margin-bottom: 16px;
}

.cust_cat_name {
  font-size: 14px;
  columns: #f55000;
  font-weight: 700;
  text-transform: capitalize;
}
.cust_cat_text {
  font-size: 10px;
  color: grey;
}

.list_item {
  margin-bottom: 4px;
  font-size: 12px;
  display: flex;
  justify-content: space-between;
}
.list_item:last-of-type {
  margin-bottom: 8px;
}

.list_item:first-of-type {
  margin-top: 8px;
}

.list_item svg {
  fill: #f55000;
}

.radio_cust:first-of-type {
}
.check_cust {
}
.check_cust :first-of-type {
}
.button_div {
  width: calc(100vw - 16px);
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  position: fixed;
  bottom: 120px;
  left: 8px;
  right: 8px;
  height: 50px;
  background-color: rgba(230, 229, 229, 1);
  margin-bottom: 10px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.cust_add_button {
  width: 40%;
  background: #00873d;
  color: white;
  height: 40px;
  border-radius: 12px;
  font-size: 14px;
  text-align: center;
}

.cust_cancel_button {
  width: 40%;
  background: #ff4040;
  color: white;
  height: 40px;
  border-radius: 12px;
  font-size: 14px;
  text-align: center;
}

.productInfo {
  height: 100px;
  width: 100%;
  display: flex;
  padding: 10px;
  margin-left: 5%;
}

.productImage {
  height: 80px;
  width: 80px;
  border-radius: 100%;
}

.productImage img {
  border-radius: inherit;
  object-fit: cover;
}

.productDescription {
  width: 100%;
  padding-top: 20px;
}

.productDescription p {
  color: 100%;
  margin-left: 20px;
  color: #f55000;
}

.headerDiv {
  height: 30%;
  background-color: grey;
}

.btnGroup {
  display: block;
  padding: 30px;
  position: absolute;
  bottom: 0;
  width: 100%;
}

.btnGroup input {
  border: 1px solid #bb0000;
  width: 100%;
  margin-bottom: 10px;
  height: 60px;
  color: #bb0000;
  padding: 10px;
  background: transparent;
  border-left: none;
  border-right: none;
  border-top: none;
  font-size: 30px;
}

.btnGroup button {
  background-color: #bb0000;
  width: 100%;
  height: 40px;
  color: white;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
  border-radius: 5px;
  margin-bottom: 10px;
}
.infoText {
  padding: 12px;
  width: 100%;
  text-align: left;
  margin: auto;
  border-radius: 3px;
  top: 5px;
  position: relative;
  color: black;
  font-size: 14px;
  font-weight: bold;
}

.seatNo {
  display: block;
  text-align: center;
  padding: auto;
  font-size: 48px;
  color: #bb0000;
  font-weight: bold;
  margin-top: 40px;
}
.swal-button {
  background-color: tomato !important;
}

.qrdiv {
  width: 50%;
  align-items: center;
  margin: auto;
}

.header {
  display: flex;
}

.headerImage {
  padding: 12px;
}

.headerImage img {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  margin: 20px;

  object-fit: cover;
  object-position: center right;
}
