.maindiv {
  display: flex;
  flex-direction: row;
  align-content: center;
  height: 50px;
  text-transform: uppercase;
}

.status_indicator {
  height: 50px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.inactiveCircle {
  height: 25px;
  width: 25px;
  border-radius: 100%;
  background: white;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.line {
  height: 50px;
  width: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 25px;
}

.line > div {
  height: 1px;
  width: 100%;
  background: white;
}

.status_text {
  font-size: 6px;
  line-height: 10px;
  text-align: center;
  width: 100%;
  margin-top: 3px;
}
.pickedup_div {
  text-align: center;
}

.pickedup_div h6 {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 12px;
  letter-spacing: 1px;
}

.pickedUpBtn {
  color: #f55000;
  font-size: 10px;
  letter-spacing: 3px;
  height: 40px;
  min-width: 100px;
  border-radius: 5px;
  background-color: transparent;
  border: 1px solid white;
  margin: 5px;
  -webkit-animation: glowing 1500ms infinite;
  -moz-animation: glowing 1500ms infinite;
  -o-animation: glowing 1500ms infinite;
  animation: glowing 1500ms infinite;
  font-weight: bold;
}

@-webkit-keyframes glowing {
  0% {
    background-color: rgb(255, 255, 255);
    -webkit-box-shadow: 0 0 2px rgb(255, 255, 255, 0.5);
  }
  50% {
    background-color: rgb(255, 255, 255);
    -webkit-box-shadow: 0 0 10px rgb(255, 255, 255, 0.5);
  }
  100% {
    background-color: rgb(255, 255, 255);
    -webkit-box-shadow: 0 0 2px rgb(255, 255, 255, 0.5);
  }
}

@-moz-keyframes glowing {
  0% {
    background-color: rgb(255, 255, 255);
    -moz-box-shadow: 0 0 2px rgb(255, 255, 255, 0.5);
  }
  50% {
    background-color: rgb(255, 255, 255);
    -moz-box-shadow: 0 0 10px rgb(255, 255, 255, 0.5);
  }
  100% {
    background-color: rgb(255, 255, 255);
    -moz-box-shadow: 0 0 3px rgba(255, 255, 255, 0.5);
  }
}

@-o-keyframes glowing {
  0% {
    background-color: rgb(255, 255, 255);
    box-shadow: 0 0 2px rgb(255, 255, 255, 0.5);
  }
  50% {
    background-color: rgb(255, 247, 247);
    box-shadow: 0 0 10px rgb(255, 255, 255, 0.5);
  }
  100% {
    background-color: rgb(255, 255, 255);
    box-shadow: 0 0 2px rgb(255, 255, 255, 0.5);
  }
}

@keyframes glowing {
  0% {
    background-color: rgb(255, 255, 255);
    box-shadow: 0 0 3px rgb(255, 255, 255, 0.5);
  }
  50% {
    background-color: rgb(255, 255, 255);
    box-shadow: 0 0 10px rgb(255, 255, 255, 0.5);
  }
  100% {
    background-color: rgb(255, 255, 255);
    box-shadow: 0 0 3px rgb(255, 255, 255, 0.5);
  }
}
.backdrop {
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
}
.rating_modal_close {
  height: 0;
  display: none;
  transition: all 0.6s;
}
.rating_modal {
  width: 80%;
  z-index: 99;
  position: fixed;
  top: 25%;
  left: 10%;
  right: 10%;
  height: auto;
  background-color: white;
  border-radius: 6px;
  transition: all 0.6s;
}
.emoji_container {
  display: flex;
  justify-content: space-evenly;
  margin-top: 16px;
  padding: 12px;
}

.emoji_container > img {
  height: 24px;
}

.ratingTitle {
  font-size: 15px;
  color: black;
  margin-bottom: 16px;
  padding: 12px;
}
.close_button {
  position: absolute;
  top: 8px;
  right: 8px;
}
.rating {
  /* box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2); */
  object-fit: contain;
  transition: all 0.3s;
}

.input_div {
  min-height: 50px;
  width: 100%;
  padding: 12px;
}

.selected_rating {
  object-fit: contain;
  transition: all 0.3s;
  transform: scale(1.5);
}

.button_div {
  display: flex;
  justify-content: space-evenly;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.text_area {
  width: 100%;
  margin: 16px auto;
  border-radius: 8px;
  border: 1px solid black;
  font-size: 12px;
  height: 80px;
}

.cancel_button {
  height: 40px;
  font-size: 14px;
  color: white;
  background-color: #f55000;
  width: 50%;
  border-bottom-left-radius: 6px;
}

.submit_button {
  height: 40px;
  font-size: 14px;
  color: white;
  background-color: teal;
  width: 50%;
  border-bottom-right-radius: 6px;
}
