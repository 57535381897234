.datarow p {
  color: white;
}

@media only screen and (min-width: 600px) {
  .maindiv {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 16px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    overflow: scroll;
    margin: auto;
  }
}

@media only screen and (max-width: 600px) {
  .maindiv {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 16px;
    background-size: cover;
    overflow: scroll;
  }
}

@media only screen and (min-width: 600px) {
  .welcome_text_div {
    font-weight: bold;
    letter-spacing: 3px;
    position: absolute;
    top: 200px;
    left: 0;
    right: 0;
    padding: 8px 16px;
    color: white;
    max-width: 800px;
    margin: auto;
  }
}

@media only screen and (max-width: 600px) {
  .welcome_text_div {
    font-weight: bold;
    letter-spacing: 3px;
    position: absolute;
    top: 100px;
    left: 0;
    right: 0;
    padding: 8px 16px;
    color: white;
    max-width: 800px;
    margin: auto;
  }
}

/* .maindiv {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 16px;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: scroll;
} */

.welocme_text {
  font-size: 24px;
  animation: show 1s;
}
.highlight_text {
  font-size: 300%;
  line-height: 40px;
  animation: show 1s;
  /* linear infinite; */
}

@keyframes show {
  0% {
    margin-left: -500px;
  }
  100% {
    margin-left: 0px;
  }
}

.ordermodeDiv {
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  padding: 16px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  max-width: 600px;
  margin: auto;
}

.ordermodepanel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f55000;
  height: 50px;
  width: 90%;
  border: 0.5px rgba(229, 62, 36, 0.7) solid;
  font-size: 12px;
  border-radius: 4px;
  padding: 8px;
  color: white;
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.54);
  margin: 8px 0px;
}
.ordermodepanel:nth-last-child(2) {
  margin-bottom: 16px;
}
.ordermodepanel > p {
  line-height: 35px;
  width: 100%;
  vertical-align: middle;
  font-size: 14px;
  font-family: Arial, Helvetica, sans-serif;
  letter-spacing: 3px;
  text-align: center;
}

.boarding_pass {
  margin-top: 90px;
}

.destinationdiv {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  margin-top: 16px;
  padding: 0 2vh;
}

.destinationdiv div {
  width: 100%;
  text-align: center;
  font-size: 24px;
  color: white;
}

.destinationdiv > p {
  color: rgba(255, 255, 255, 1);
  font-size: 30px;
  font-weight: 600;
}

.planeImage {
  height: 30px;
  width: 40px;
  object-fit: contain;
}

.boardingpass {
  /* border: 0.5px solid rgba(255, 255, 255, 1); */
  /* background: radial-gradient(
    circle,
    rgba(254, 254, 255, 1) 0%,
    rgba(225, 232, 240, 1) 100%
  ); */
  box-shadow: 0 0 3px 2px 0 rgba(0, 0, 0, 0.21);
  width: 100%;
  margin-top: 16px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #0f1f39;
  border-radius: 8px;
}

.datarow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 8px 0;
}

.datarow > div {
  flex: 1;
}

.fieldtitle {
  color: #9c9c9c !important;
  font-size: 12px;
  text-align: left;
}

.fieldtitleRight {
  text-align: right !important;
}

input {
  color: white;
  font-size: 16px;
  font-weight: 600;
  background: transparent;
  border: none;
  width: 150px;
}

.submitbutton {
  background-color: transparent;
  border: 0.7px solid #e53e24 !important;
  margin-top: 12px;
  border: none;
  width: 100%;
  margin: 8px auto;
  padding: 8px 0;
  border-radius: 8px;
  text-align: center;
  color: white;
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 3px;
}

.destinationText {
  font-size: 14px;
  font-weight: bold;
}

.flightNumber {
  color: rgba(229, 62, 36, 1);
  font-size: 14px;
  font-weight: bold;
}

.changeRegionButton {
  height: 40px;
  margin-top: 24px;
  border: solid 1px rgba(229, 62, 36, 1);
  background: transparent;
  color: rgba(229, 62, 36, 1);
  width: 80%;
}

.deliveryAlert {
  padding: 0 !important;
  margin: 8px 0;
  color: white;
  font-size: 14px;
}
.deliveryAlert span {
  font-weight: bold;
  text-decoration: underline;
}

.select_lable {
  font-size: 16px;
  color: white;
  line-height: 30px;
  vertical-align: middle;
}

.select_div {
  display: flex;
  justify-content: space-between;
}

.select_delivery_slot {
  height: 30px;
  margin-left: 10px;
  font-size: 14px;
  color: rgba(229, 62, 36, 1);
}

.select_option {
  width: 100%;
  color: black;
  background-color: whitesmoke;
  font-size: 14px;
  line-height: 20px;
  vertical-align: middle;
}
.swal_close_button {
  background-color: rgba(229, 62, 36, 1) !important;
}

.layoverloading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  color: white;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
}

.layoverloading > p {
  margin-top: 16px;
  font-size: 16px;
}

@media only screen and (max-width: 340px) {
  .fieldText {
    font-size: 12px;
  }
  .fieldtitle {
    font-size: 12px;
  }
  .boardingpass {
    padding: 16px 16px;
  }
  .datarow {
    margin: 4px 0;
    text-align: left;
  }
  .deliveryAlert {
    font-size: 12px;
  }
  .submitbutton {
    margin-top: 12px;
    padding: 4px 0;
  }
}
