.main {
    margin-top: 8px;
  }
  .input_div {
    display: flex;
    flex: 1;
    max-width: 100%;
    border: 1px solid #ff5500;
    border-radius: 4px;
  }
  .input {
    flex: 1;
    padding-left: 12px;
  }
  
  .apply_button {
    border-radius: 0 !important;
    background-color: #bb0000;
    color: white;
  }
  